import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    davisImage: file(relativePath: { eq: "davis.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithPickingDoorLockImage: file(
      relativePath: { eq: "locksmith-picking-door-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laserKeyMachineImage: file(relativePath: { eq: "laser-key-machine.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function DavisPage({ data }) {
  return (
    <MainLayout
      title="24 Hr Locksmith Near Me | Davis, CA | (916) 995-0135"
      description="Looking for reliable locksmith services in Davis, CA? We do emergency lockouts, key cutting, lock repairs & more. Immediate Response. Best Price Guarantee. "
      offset
    >
      <ContactHeading background={data.davisImage.childImageSharp.fluid}>
        <h1 className="text-center">Davis Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Davis Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <section>
        <div className="background-content bg-dark text-white pb-0 pb-md-6">
          <Container>
            <h2 className="text-center">Full Service Locksmith in Davis, CA</h2>
            <Row>
              <Col xs={12} md={6}>
                <p className="indent">
                  Swift Locksmith is a local, family owned company, proudly
                  serving Davis, CA and the surrounding areas. Our highly
                  skilled team of locksmith technicians are ready to assist you
                  in virtually any locksmith situation whether it's losing your
                  car keys, being locked out from your house, desiring lock
                  replacements or even upgrading your home/business security. We
                  understand that some of these unforeseen situations can happen
                  at any given time and can be stressful and that is why we're
                  always accessible, 24 hours a day, 7 days a week which means
                  you can return to your regular routine.
                </p>
                <p className="indent mb-md-0">
                  We would like to guarantee that all of our customers, new and
                  existing, consistently get treated with the utmost customer
                  support, professionalism and urgency. We offer competitive
                  pricing so there are never any hidden fees because we are
                  always honest with you.
                </p>
              </Col>
              <Col xs={12} md={6} className="col-sm-pad">
                <Img
                  fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
                  alt="locksmith-van-in-city"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section">
        <h2>What We Offer</h2>
        <h3>Residential Services</h3>
        <p className="indent">
          It's very crucial to get a sense of security in your own home and our
          goal is to supply you with the greatest residential locksmith
          solutions. We offer different types of key locks such as deadbolts,
          knob sets and perhaps even wireless locks which means that you may
          pick what works best for you and your home. Our technicians provide
          different security options from basic to high security and to
          accommodate different budgets.
        </p>
        <p className="indent mb-5">
          If you are looking to repair your locks, rekey your new home or
          attempting to upgrade your home security but are not familiar with
          what the best route for you is, give Swift Locksmith a call and we
          will happily provide assistance and options for you.
        </p>
        <h3>Commercial Services</h3>
        <p className="indent">
          Regardless of whether you have an office, little neighborhood store, a
          private practice or a studio, you need to ensure your business is
          always secured and we are able to help you get premium locksmith
          protection. Our professionals offer a customized plan for every client
          dependent on what they are searching for so we always have your needs
          at the top of our priority list.
        </p>
        <p className="indent mb-5">
          We utilize the latest technology to offer better and newer features
          for commercial security. From business lock outs, changing/repairing
          locks to electronic pads, we have you covered. Our purpose is to
          safeguard your own goods, inventory, staff and customers so that you
          have less to worry about and more time and energy to concentrate on
          your business.
        </p>
        <h3>Automotive Services</h3>
        <p className="indent">
          We provide 24/7 emergency services in Davis, CA and surrounding areas.
          Our technicians have been providing auto locksmith services for years
          and are experts in the automotive locksmith industry. We service all
          sorts of models and makes therefore you can be sure that we have you
          covered!
        </p>
        <p className="indent mb-0">
          Whether you're locked away from your vehicle, lost your keys or
          seeking to acquire transponder processor keys to list a few, Swift
          Locksmith technicians are here to fix all of your automobile locksmith
          needs quickly and effectively. We are always pleased to assist you, so
          give Swift Locksmith in Davis a call today and see what we can do for
          you!
        </p>
      </Container>
      <Container as="section">
        <h2>Choosing Davis Swift Locksmith</h2>
        <p className="indent">
          Swift Locksmith is a certified locksmith company serving the Davis, CA
          community for over 10 years which means you can always count on us for
          reliable, fast and affordable locksmith services. We offer an
          assortment of services for commercial, residential and automotive
          needs. Whether you're wanting to have your house re-keyed, your car or
          truck lock mended, or you find yourself locked out of your own office
          or home, we can quickly assist you.
        </p>
        <p className="indent">
          All of our technicians have been trained to resolve a wide range of
          locksmith needs and are professional and friendly. Because Swift
          Locksmith is a mobile company, all of our vans are completely
          furnished with the right equipment for any locksmith job and we always
          come to you for your locksmith service needs.
        </p>
        <Row className="mb-3">
          <Col xs={12} md={4} className="col-sm-pad">
            <Img
              fluid={data.locksmithPickingDoorLockImage.childImageSharp.fluid}
              alt="locksmith-picking-door-lock"
            />
          </Col>
          <Col xs={12} md={4} className="d-none d-md-block">
            <Img
              fluid={data.laserKeyMachineImage.childImageSharp.fluid}
              alt="laser-key-machine"
            />
          </Col>
          <Col xs={12} md={4} className="col-sm-pad">
            <Img
              fluid={data.locksmithCarInNeighborhoodImage.childImageSharp.fluid}
              alt="locksmith-car-in-neighborhood"
            />
          </Col>
        </Row>
        <p className="indent mb-0 pb-5">
          Our goal is to make sure that people feel safe within the Davis
          community and it is our job to offer top notch locksmith services to
          get every person to have a sense of security in their house, business
          or inside their vehicle. Whether you have an urgent situation or
          non-emergency locksmith need, we are always available to assist you,
          so don't be afraid to call and we will be happy to chat with you!
        </p>
      </Container>
    </MainLayout>
  )
}

export default DavisPage
